import { useState, useEffect, useRef } from "react";
import GreenTicket from "../../../assets/images/green-tick.png";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';

const MyCartRow = ({ cartitem, team, refreshMyCart }) => {
    const [quantity, setQuantity] = useState(parseInt(cartitem.item_quantity));
    const [cartLoad, setCartLoad] = useState(false);
    const [delLoad, setDeleteLoad] = useState(false);
    const hasRowRendered = useRef({effect:false});
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token') !== undefined
        ? localStorage.getItem('auth_token')
        : '';

    const increment = () => {
        if (!cartLoad) {
            setQuantity(prevquantity => prevquantity < cartitem.quantity ? ++prevquantity : cartitem.quantity);
        }
    }

    const decrement = () => {
        if (!cartLoad) {
            setQuantity(prevquantity => prevquantity > 1 ? --prevquantity : 1);            
        }
    }

    async function updateBasket() {
        setCartLoad(true);
        var formdata = new FormData();
        formdata.append("type", "update_cart");
        formdata.append("team_id", team._id);
        formdata.append("cart_details_id", cartitem.cart_details_id);
        formdata.append("quantity", quantity);
        var secureHeader = new Headers();
        secureHeader.append('Authorization', 'Bearer ' + token);
        secureHeader.append('device_type', 'W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_checkout', requestOptions)
            .then(response => response.text())
            .then(result => {
                setCartLoad(false);
            })
            .catch(error => console.log('error', error));
    }

    const deleteCartItemAction=async()=>{
        setDeleteLoad(true);
        var formdata = new FormData();
        formdata.append("type", "delete_cart");
        formdata.append("cart_details_id", cartitem.cart_details_id);
        formdata.append("team_id", team._id);

        var secureHeader = new Headers();
        secureHeader.append('Authorization', 'Bearer ' + token);
        secureHeader.append('device_type', 'W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_checkout', requestOptions)
            .then(response => response.text())
            .then(result => {
                setDeleteLoad(false);
                refreshMyCart();
            })
            .catch(error => console.log('error', error));
    }

    const delteCartItem = async () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='confirm-ui'>
                        <div className="font-semibold uppercase text-[22px] leading-[30px] text-center mb-5">PLAI</div>
                        <p className="text-center text-[14px] leading-[18px] mb-5">Are you sure you want to delete this Cart item?</p>
                        <div className="flex justify-center">
                            <button className="button button-blue mx-1" onClick={onClose}>No</button>
                            <button className="button mx-1"
                            onClick={() => { 
                                deleteCartItemAction();                       
                                onClose();
                            }}
                            >
                            Yes
                            </button>
                        </div>
                    </div>
        )}});
    }

    const personalizeInformation=()=>{
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                <div className='confirm-ui w-96 !max-w-[480px]'>
                    <div className="font-semibold uppercase text-[22px] leading-[30px] text-center mb-5">Product Details</div>
                        {cartitem.shirt_no!=='' &&
                            <div className='flex flex justify-between items-center w-full mb-8 max-w-[448px]'>
                                <span className="mb-1.5 block">Shirt Number</span>
                                <div className="font-medium">{cartitem.shirt_no}</div>
                            </div>
                        }
                        {cartitem.shirt_name!=='' &&
                            <div className='flex flex justify-between items-center w-full mb-8 max-w-[448px]'>
                                <span className="mb-1.5 block">Shirt Name</span>
                                <div className="font-medium">{cartitem.shirt_name}</div>
                            </div>
                        }
                        {cartitem.size!=='' &&
                        <div className='flex flex justify-between items-center w-full mb-8 max-w-[448px]'>
                            <span className="mr-5">Size</span>
                            <div className="font-medium">{cartitem.size}</div>
                        </div>
                        }
                        {cartitem.sleeve!=='' &&
                        <div className='flex flex justify-between items-center w-full mb-8 max-w-[448px]'>
                            <span className="mr-5">Sleeve</span>
                            <div className="font-medium">{cartitem.sleeve}</div>
                        </div>
                        }
                        {cartitem.color!=='' &&
                        <div className='flex flex justify-between items-center w-full mb-8 max-w-[448px]'>
                            <span className="mr-5">Color</span>
                            <div className="font-medium">{cartitem.color}</div>
                        </div>
                        }
                        {cartitem.gender!=='' &&
                        <div className='flex flex justify-between items-center w-full mb-8 max-w-[448px]'>
                            <span className="mr-5">Select Gender</span>
                            <div className="font-medium">{cartitem.gender}</div>
                        </div>
                        }
                    <div className="flex justify-center">
                        <button className="button button-blue mx-1" onClick={onClose}>Close</button>                        
                    </div>
                </div>
                );
            }
        });
    }
    useEffect(()=>{
        if(hasRowRendered.current['effect']){
            updateBasket();
        }
        hasRowRendered.current['effect']=true;
    },[quantity]);

    return (
        <>
            <div className="flex flex-col md:flex-row items-center w-full lg:w-1/2 mb-5 lg:mb-0">
                <div className="relative w-[85px] h-[85px] mr-3.5">
                    <img src={cartitem.image} className="absolute w-full h-full object-cover top-0 left-0 rounded-[14px]" alt="" />
                </div>
                <div className="flex-1 lg:max-w-[465px] text-[16px] leading-[24px] font-bold">
                    {(cartitem.is_personalize) ?
                        <>
                        <div className="flex gap-2.5">
                            <span>{cartitem.name}</span>
                            <button onClick={()=>{personalizeInformation()}} className="w-[20px] lg:w-[30px]">
                                <svg width="20" height="20" className="w-full" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15Z" fill="#363F6C"/>
                                    <path d="M16.9398 12.6015L13.1752 13.0733L13.0404 13.698L13.7802 13.8344C14.2635 13.9495 14.3588 14.1238 14.2536 14.6054L13.0404 20.3066C12.7215 21.7812 13.213 22.4749 14.3687 22.4749C15.2646 22.4749 16.3052 22.0607 16.7771 21.4919L16.9217 20.808C16.5929 21.0973 16.1129 21.2124 15.794 21.2124C15.3419 21.2124 15.1775 20.8951 15.2942 20.3362L16.9398 12.6015ZM17.0549 9.16895C17.0549 9.60495 16.8817 10.0231 16.5734 10.3314C16.2651 10.6397 15.8469 10.8129 15.4109 10.8129C14.9749 10.8129 14.5568 10.6397 14.2485 10.3314C13.9402 10.0231 13.767 9.60495 13.767 9.16895C13.767 8.73296 13.9402 8.31482 14.2485 8.00652C14.5568 7.69822 14.9749 7.52502 15.4109 7.52502C15.8469 7.52502 16.2651 7.69822 16.5734 8.00652C16.8817 8.31482 17.0549 8.73296 17.0549 9.16895Z" fill="#FBDB03"/>
                                </svg>                
                            </button>
                        </div>
                        </>
                    :
                        <>{cartitem.name}</>
                    }
                    {cartitem.purchase_in_installment &&
                        <p className="flex green-tick items-center">
                            <img src={GreenTicket} alt=""></img> <span className="ml-2">Payment Plan Activated</span>
                        </p>
                    }
                </div>
            </div>
            <div className="w-full lg:w-1/2">
                <div className="flex w-full justify-between flex-wrap md:flex-nowrap lg:justify-end items-center">
                    <div className="quantity-field flex justify-center items-center relative w-full md:w-[220px] md:mr-[40px] mb-4 md:mb-0">
                        {!cartLoad ?
                            <>
                                <button className="quantity-btn quantity-minus" onClick={()=>{decrement();}}></button>
                                <input className="quantity-input" type="text" value={quantity} readOnly />
                                <button className="quantity-btn quantity-plus" onClick={()=>{increment();}}></button>
                            </>
                            :
                            <>
                                <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={30} height={30} alt="" />
                            </>
                        }
                    </div>
                    <div className="w-full md:mr-[40px] md:w-[100px] mb-4 md:mb-0">
                        <div className="text-[10px] leading-[15px] text-[#363F6C] uppercase">Price</div>
                        <div className="text-[#363F6C] text-[24px] leading-[36px] font-bold">{team.currency_symbol}{(cartitem.display_price_with_transactionfee / 100).toFixed(2)}</div>
                    </div>
                    <div className="mr-[40px] w-[100px]">
                        <div className="text-[10px] leading-[15px] text-[#363F6C] uppercase">Total</div>
                        <div className="text-[#363F6C] text-[24px] leading-[36px] font-bold">{team.currency_symbol}{((quantity * cartitem.display_price_with_transactionfee) / 100).toFixed(2)}</div>
                    </div>
                    <div className="pt-2.5">
                        {!delLoad ?
                            <button type="button" className="button-tiny delete rounded-full !w-[30px] !h-[30px]" onClick={() => { delteCartItem() }}></button>
                            :
                            <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={30} height={30} alt="" />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default MyCartRow;