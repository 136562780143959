import { useContext, useState, useEffect, useRef } from 'react';
import { useParams, NavLink, useNavigate } from 'react-router-dom';
import { useAuthState } from '../../../contexts/AuthContext';
import Sidebar from '../../../components/Sidebar/Sidebar';
import AdminNavbar from '../../../components/AdminNavbar/AdminNavbar';
import CheckoutForm from '../../../components/Stripe/StripeCard';
import { getTransitionFees, getMyCart, getWallet, getProfile } from '../../../libs/dataFunctions';
import { TeamContextProvider, TeamContext } from "../../../contexts/TeamContext";
import NoNews from "../../../assets/images/noproduct.png";
import Spinner from '../../../components/Spinner/Spinner';
import GreenTicket from "../../../assets/images/green-tick.png";
import InfoIconWhite from '../../../assets/images/info-white.svg';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

const ShopcartView=()=>{
    const {team, teamUpdating} = useContext(TeamContext);
    const [tip, setTip] = useState(false);
    const [tipAmount, setTipAmount] = useState(0);
    const [tipTransfer, setTipTransfer] = useState('club');
    const [clientSecret, setClientSecret] = useState(null);
    const [intentId, setIntentId] = useState(null);
    const [transactionFee, setTransactionFee] = useState(0);
    const [walletTransactionFee, setWalletTransactionFee] = useState(0);
    const [receiptEmail, setReceiptEmail] = useState("");
    const [referenceChild, setReferenceChild] = useState('');
    const [address, setAddress] = useState('');
    const [error, setError] = useState('');
    const [loading,setLoading] = useState(false);
    const [loadingSecrete,setLoadingSecrete] = useState(false);

    const [mycartdata,setMyCartData] = useState([]);
    const [cartTotal, setCartTotal]  = useState(0);
    const [payTodayAmount, setPayTodayAmount]  = useState(0);
    const [payTodayAmountDisplay, setPayTodayAmountDisplay]  = useState(0);
    const [discountAmount, setDiscountAmount] = useState(0);

    const [promocode, setPromocode] = useState('');
    const [promoloading, setPromoLoading] = useState(false);
    const [promoCodeError, setPromoCodeerror] = useState(null);
    const [promoCodeSuccess, setPromoCodeSuccess] = useState(false);
    const [promoDiscountAmount, setPromoDiscountAmount] = useState(0);
    const [currentUser, setCurrentUser] = useState({});

    const [walletData, setWalletData] = useState([]);
    const [isWallet, setWallet] = useState(false);

    const navigate = useNavigate();
    const promoRef = useRef();
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';    

    const applyPromoCode=async()=>{
        setPromoCodeerror(null);
        if(promocode!==""){
            setPromoLoading(true);            
            setPromoCodeSuccess(false);
            let formdata = new FormData();
            formdata.append('type', 'apply_promocode');
            formdata.append('team_id', team._id);
            formdata.append('promocode',promocode);
            if(payTodayAmount > 0){
                formdata.append('amount',payTodayAmount);            
            }
            else{
                formdata.append('amount',cartTotal);
            }
            
            var secureHeader = new Headers();
            secureHeader.append('Authorization', 'Bearer ' + token);
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };
            await fetch(process.env.REACT_APP_APIURL + '/app_promocode', requestOptions)
                .then(response => response.text())
                .then(result => {
                    setPromoLoading(false);
                    var rs = JSON.parse(result);
                    if (rs.status!==1) {
                        setPromoCodeerror(rs?.message);
                    }
                    else {
                        setPromoCodeSuccess(true);
                        setPromoDiscountAmount(rs?.discount_amount);
                    }
                })
                .catch(error => console.log('error', error));
        }
        else{
            setPromoCodeerror("Please Enter Promocode");
        }
    }

    const getPaymentIntent = async()=>{
        setLoadingSecrete(true);
        let formdata = new FormData();
        formdata.append('type','stripe_payment_intent_v2');
        formdata.append('receiptEmail',receiptEmail);
        formdata.append('notes',referenceChild);
        formdata.append('teamId', team._id);
        formdata.append('tip',tip);
        formdata.append('address',address);
        formdata.append('promocode',promocode);
        if(isWallet){
            formdata.append('use_wallet',isWallet);
        }
        if(tip){
            formdata.append('tip_amount',tipAmount);
            formdata.append('transfer_tip_to',tipTransfer);
        }

        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
        await fetch(process.env.REACT_APP_APIURL + '/app_stripe_payment_intent' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoadingSecrete(false);
            var rs = JSON.parse(result);
            if (rs?.status!==1 && rs?.status!==4) {
                setError(rs?.message);
            }
            else{
                if(rs?.status===4){
                    navigate('/thankyou');
                }
                else{
                    setIntentId(rs?.data?.intent?.id);
                    setClientSecret(rs?.data?.intent?.client_secret);
                }
            }
        })
        .catch(error => console.log('error', error));
    }

    const refreshCheckoutPage = async()=>{
        const [myCartData,myWallet, currentUserData] = await Promise.all([
            getMyCart(token, team._id),
            getWallet(token, team._id),
            getProfile(token)
        ]);

        setCurrentUser(currentUserData);
        setReceiptEmail(currentUserData?.email);

        if(myCartData.status===1){
            setMyCartData(myCartData.data);
            setCartTotal(myCartData.total_amount);
            setPayTodayAmount(myCartData.pay_today_amount);
            setDiscountAmount(myCartData.discount_amount);
            setPayTodayAmountDisplay(myCartData.pay_today_amount_display);
            if(myCartData.pay_today_amount>0){
                const [transitionData] = await Promise.all([
                    getTransitionFees(team.currency_code, token, myCartData.pay_today_amount)
                ]);
                setTipAmount(transitionData.tip);
                setTransactionFee(transitionData.total_transaction);
            }
            else{
                const [transitionData] = await Promise.all([
                    getTransitionFees(team.currency_code, token, myCartData.total_amount)
                ]);
                setTipAmount(transitionData.tip);
                setTransactionFee(transitionData.total_transaction);
            }

            if(myWallet.data.length >0 && myWallet.data[0].amount!=="" && myWallet.data[0].amount!=="0.00" && myWallet.data[0].amount!==0){
                setWalletData(myWallet.data);
                setWallet(true);
                setWalletTransactionFee(myWallet.wallet_transaction_fee);
            }
        }
        setLoading(false);
    }

    useEffect(()=>{
        setLoading(true);
        if(!teamUpdating){            
            refreshCheckoutPage();
        }        
    },[teamUpdating]);

    return(
        !loading ?        
        <>
            <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                <NavLink to="/" className='home'>
                    <span>Dashboard</span>
                </NavLink>
                <span className='separator'>&lt;</span>
                <NavLink to={`/team/${team._id}/shop`}>
                    Shop
                </NavLink>
                <span className='separator'>&lt;</span>
                <NavLink to={`/team/${team._id}/shop/mycart`}>
                    My Basket
                </NavLink>                
                <span className='separator'>&lt;</span>
                Checkout
            </div>
            <div className="body px-5 lg:px-8 py-10">
                <div className="ln-header flex justify-between items-center w-full pb-5 relative">
                    <h2 className="title mr-5 uppercase">Checkout</h2>
                    <div className="sep mr-5 sm:mr-11"></div> 
                </div>
                <div className="rounded-xl bg-white p-7.5">
                    {mycartdata.length > 0 ?
                        <>
                            <div className="cart-items">
                                {mycartdata.map((cartitem)=>{
                                    return(
                                        <div className="cart-item flex flex-wrap md:flex-nowrap border-b border-black-100/10 mb-7 pb-7" key={cartitem._id}>
                                            <div className="flex items-center w-full lg:w-1/2">
                                                <div className="relative w-[85px] h-[85px] mr-3.5">
                                                    <img src={cartitem.image} className="absolute w-full h-full object-cover top-0 left-0 rounded-[14px]" alt="" />
                                                </div>
                                                <div className="flex-1 max-w-[465px] text-[16px] leading-[24px] font-bold">
                                                    {cartitem.name}
                                                    {
                                                        cartitem.purchase_in_installment &&
                                                        <p className="flex green-tick items-center">
                                                            <img src={GreenTicket} alt=""></img> <span className="ml-2">Payment Plan Activated</span>
                                                        </p>
                                                    }
                                                </div>
                                            </div>
                                            <div className="flex w-full lg:w-1/2">
                                                <div className="flex w-full justify-end items-center">
                                                    <div className="mr-[40px] w-[130px]">
                                                        <div className="text-[10px] leading-[15px] text-[#363F6C] uppercase">Qty</div>
                                                        <div className="text-[#363F6C] text-[18px] leading-[27px] font-bold">{parseInt(cartitem.item_quantity)} X {team.currency_symbol}{(cartitem.display_price_with_transactionfee / 100).toFixed(2)}</div>
                                                    </div>
                                                    <div className="mr-[40px] w-[100px]">
                                                        <div className="text-[10px] leading-[15px] text-[#363F6C] uppercase">Total</div>
                                                        <div className="text-[#363F6C] text-[24px] leading-[36px] font-bold">{team.currency_symbol}{((parseInt(cartitem.item_quantity) * cartitem.display_price_with_transactionfee) / 100).toFixed(2)}</div>
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="checkout-action flex justify-end pt-12">
                            <div className='w-full md:w-1/2 md:max-w-[500px]'>
                                    {!clientSecret &&
                                    <>
                                        <div className='cart-detail mb-5'>
                                            {walletData.length > 0 && walletData[0].amount > 0 &&
                                                <div className='text-left p-3.5 rounded-[14px] text-white bg-[#363F6C] mb-5'>
                                                    <div className='flex justify-between gap-2.5'>
                                                        <div className='flex flex-1 gap-2.5'>
                                                            <div>
                                                                <label className="relative inline-flex items-center cursor-pointer">
                                                                    <input type="checkbox" value="" className="sr-only peer" onChange={()=>{setWallet(!isWallet)}} checked={isWallet ? true : false} />
                                                                    <div className="w-11 h-6 bg-[rgba(255,255,255,0.1)] peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:after:bg-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#FBDB09]"></div>                        
                                                                </label>
                                                            </div>
                                                            <div className="uppercase">Use PLAI Wallet</div>
                                                        </div>
                                                        <div>
                                                            <span className='text-xl font-bold'>{walletData[0].currency_symbol}{parseFloat(walletData[0].amount).toFixed(2)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div className='text-left p-3.5 rounded-[14px] text-white bg-[#363F6C] mb-5'>
                                                <div className='flex justify-between'>
                                                    <div className="uppercase">Add A Tip : <span className='text-[#FBDB09]'>{team.currency_symbol}{(tipAmount / 100).toFixed(2)}</span></div>
                                                    <div>
                                                        <label className="relative inline-flex items-center cursor-pointer">
                                                            <input type="checkbox" value="" className="sr-only peer" onChange={()=>{setTip(!tip)}} checked={tip ? true : false} />
                                                            <div className="w-11 h-6 bg-[rgba(255,255,255,0.1)] peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:after:bg-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#FBDB09]"></div>                        
                                                        </label>
                                                    </div>
                                                </div>
                                                {tip &&
                                                    <div className='flex relative'>
                                                        <div className="flex items-center mr-4">
                                                            <input id="tip-club" type="radio" value="club" name="transfer_tip_to" onChange={(e)=>{setTipTransfer('club');}} className="w-4 h-4 text-white bg-white border-white" checked={tipTransfer==='club' ? true : false} />
                                                            <label htmlFor="tip-club" className="ml-2 mb-0 text-[14px] text-white">Tip your CLUB</label>
                                                        </div>
                                                        <div className="flex items-center">
                                                            <input id="tip-donate" type="radio" value="charity" name="transfer_tip_to" onChange={(e)=>{setTipTransfer('charity');}} className="w-4 h-4 text-white bg--white border-white" checked={tipTransfer==='charity' ? true : false} />
                                                            <label htmlFor="tip-donate" className="ml-2 mb-0 text-[12px] text-white inline-flex">Make a DONATION <span className='cursor-pointer relative ml-2.5'><img src={InfoIconWhite} alt="" id="tip-info"/></span></label>
                                                                <Tooltip anchorSelect="#tip-info" style={{ zIndex: 99 }}>
                                                                    <div className="w-[280px]">
                                                                        <h3 className="uppercase text-[22px] leading-[33px] font-semibold border-b border-b-[#FBDB03] border-b-solid text-center">PLAI</h3>
                                                                        <div className="mb-4 text-center text-[12px] leading-[19px]">
                                                                            <p className='mb-2.5'>The PLAIground foundation seeks to change lives through the vehicle of sport.</p>
                                                                            <p className='mb-2.5'>A registered charity, the foundation commits to using the kind donations from the PLAI sport family to build sporting facilities in low socioeconomic communities that are in desperate need of support.</p>
                                                                            <p className='mb-2.5'>The vision, is to use the vehicle of sport to keep children off the streets and away from trouble, instead investing energy and vision into excelling in sport.</p>
                                                                            <p>Round up your purchase today and help us to change as many young lives as possible.</p>
                                                                        </div>
                                                                    </div>
                                                                </Tooltip>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            {payTodayAmount>0 &&
                                                <div className='cart-detail-row flex justify-between pb-2.5 mb-2.5'>
                                                    <div className='cart-detail-label'>To Pay Today</div>
                                                    <div className='cart-detail-value'>{team.currency_symbol}{(payTodayAmountDisplay / 100).toFixed(2)}</div>
                                                </div>
                                            }
                                            {(discountAmount>0 || promoDiscountAmount>0) &&
                                                <div className='cart-detail-row flex justify-between pb-2.5 mb-2.5'>
                                                    <div className='cart-detail-label'>Discount</div>
                                                    <div className='cart-detail-value'>{team.currency_symbol}{((discountAmount + promoDiscountAmount) / 100).toFixed(2)}</div>
                                                </div>
                                            }
                                            <div className={`cart-detail-row cart-detail-total flex justify-between pb-5 ${isWallet ? 'mb-5' : 'mb-11'}`}>
                                                <div className='cart-detail-total-label'>Basket Total</div>
                                                <div className='cart-detail-total-value'>
                                                    {!loading ?
                                                    <>
                                                        {payTodayAmount > 0 ?
                                                        <>
                                                            {tip ? 
                                                                <>
                                                                {isWallet ?
                                                                    <>
                                                                        {parseFloat(walletData[0].amount) >= (((payTodayAmount + walletTransactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2) ?
                                                                        <>
                                                                            {team.currency_symbol}{(((payTodayAmount + walletTransactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2)}
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {team.currency_symbol}{(((payTodayAmount + transactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2)}
                                                                        </>
                                                                        }
                                                                    </>
                                                                :
                                                                    <>
                                                                        {team.currency_symbol}{(((payTodayAmount + transactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2)}
                                                                    </>
                                                                }                                                                
                                                                </>
                                                                :
                                                                <>
                                                                {isWallet ?
                                                                <>
                                                                    {parseFloat(walletData[0].amount) >= (((payTodayAmount + walletTransactionFee) - promoDiscountAmount) /100).toFixed(2) ?
                                                                    <>
                                                                        {team.currency_symbol}{(((payTodayAmount + walletTransactionFee) - promoDiscountAmount) /100).toFixed(2)}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {team.currency_symbol}{(((payTodayAmount + transactionFee) - promoDiscountAmount) /100).toFixed(2)}
                                                                    </>
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    {team.currency_symbol}{(((payTodayAmount + transactionFee) - promoDiscountAmount) /100).toFixed(2)}
                                                                </>
                                                                }                                                                
                                                                </>                                                        
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                        {tip ? 
                                                            <>
                                                            {isWallet ?
                                                                <>
                                                                    {parseFloat(walletData[0].amount) >= (((cartTotal + walletTransactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2) ?
                                                                    <>
                                                                        {team.currency_symbol}{(((cartTotal + walletTransactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2)}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {team.currency_symbol}{(((cartTotal + transactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2)}
                                                                    </>
                                                                    }                                                                    
                                                                </>
                                                                :
                                                                <>
                                                                    {team.currency_symbol}{(((cartTotal + transactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2)}                                                                    
                                                                </>
                                                            }                                                            
                                                            </>
                                                            :
                                                            <>
                                                            {isWallet ?
                                                                <>
                                                                    {parseFloat(walletData[0].amount) >= (((cartTotal + walletTransactionFee) - promoDiscountAmount) /100).toFixed(2) ?
                                                                        <>
                                                                            {team.currency_symbol}{(((cartTotal + walletTransactionFee) - promoDiscountAmount) /100).toFixed(2)}
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {team.currency_symbol}{(((cartTotal + transactionFee) - promoDiscountAmount) /100).toFixed(2)}
                                                                        </>
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                {team.currency_symbol}{(((cartTotal + transactionFee) - promoDiscountAmount) /100).toFixed(2)}
                                                                </>
                                                            }                                                            
                                                            </>
                                                        }
                                                        </>
                                                        }
                                                        <p className='text-[10px] leading-[12px] font-light'>inclusive of all fees and taxes</p>
                                                    </>
                                                    :
                                                        <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={12} height={12} alt="" />
                                                    }
                                                </div>
                                            </div>
                                            {isWallet &&
                                            <>
                                            <div className='cart-detail-row cart-detail-total flex justify-between pb-5 mb-5'>
                                                <div className='cart-detail-total-label'>PLAI Wallet</div>
                                                <div className='cart-detail-total-value'>
                                                    <span className='text-green-500'>
                                                        {payTodayAmount > 0 ?
                                                        <>
                                                            {tip ? 
                                                                <>
                                                                {parseFloat(walletData[0].amount) >= (((payTodayAmount + walletTransactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2) ?
                                                                    <>
                                                                    {parseFloat(walletData[0].amount) <= (((payTodayAmount + walletTransactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2) ?
                                                                        <>-{walletData[0].currency_symbol}{walletData[0].amount}</>
                                                                        :
                                                                        <>-{walletData[0].currency_symbol}{(((payTodayAmount + walletTransactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2)}</>
                                                                    }
                                                                    </>
                                                                    :
                                                                    <>
                                                                    {parseFloat(walletData[0].amount) <= (((payTodayAmount + transactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2) ?
                                                                        <>-{walletData[0].currency_symbol}{walletData[0].amount}</>
                                                                        :
                                                                        <>-{walletData[0].currency_symbol}{(((payTodayAmount + transactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2)}</>
                                                                    }
                                                                    </>
                                                                }
                                                                </>
                                                                :
                                                                <>
                                                                {parseFloat(walletData[0].amount) >= (((payTodayAmount + walletTransactionFee) - promoDiscountAmount) /100).toFixed(2) ?
                                                                    <>
                                                                    {parseFloat(walletData[0].amount) <= (((payTodayAmount + walletTransactionFee) - promoDiscountAmount) /100).toFixed(2) ?
                                                                        <>-{walletData[0].currency_symbol}{walletData[0].amount}</>
                                                                        :
                                                                        <>-{walletData[0].currency_symbol}{(((payTodayAmount + walletTransactionFee) - promoDiscountAmount) /100).toFixed(2)}</>
                                                                    }
                                                                    </>
                                                                    :
                                                                    <>
                                                                    {parseFloat(walletData[0].amount) <= (((payTodayAmount + transactionFee) - promoDiscountAmount) /100).toFixed(2) ?
                                                                        <>-{walletData[0].currency_symbol}{walletData[0].amount}</>
                                                                        :
                                                                        <>-{walletData[0].currency_symbol}{(((payTodayAmount + transactionFee) - promoDiscountAmount) /100).toFixed(2)}</>
                                                                    }
                                                                    </>
                                                                }
                                                                </>                                                        
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {tip ? 
                                                                <>
                                                                {parseFloat(walletData[0].amount) >= (((cartTotal + walletTransactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2) ?
                                                                    <>
                                                                    {parseFloat(walletData[0].amount) <= (((cartTotal + walletTransactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2) ?
                                                                        <>-{walletData[0].currency_symbol}{walletData[0].amount}</>
                                                                        :
                                                                        <>-{walletData[0].currency_symbol}{(((cartTotal + walletTransactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2)}</>
                                                                    }
                                                                    </>
                                                                    :
                                                                    <>
                                                                    {parseFloat(walletData[0].amount) <= (((cartTotal + transactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2) ?
                                                                        <>-{walletData[0].currency_symbol}{walletData[0].amount}</>
                                                                        :
                                                                        <>-{walletData[0].currency_symbol}{(((cartTotal + transactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2)}</>
                                                                    }
                                                                    </>
                                                                }
                                                                </>
                                                                :
                                                                <>
                                                                {parseFloat(walletData[0].amount) >= (((cartTotal + walletTransactionFee) - promoDiscountAmount) /100).toFixed(2) ?
                                                                    <>
                                                                    {parseFloat(walletData[0].amount) <= (((cartTotal + walletTransactionFee) - promoDiscountAmount) /100).toFixed(2) ?
                                                                        <>-{walletData[0].currency_symbol}{walletData[0].amount}</>
                                                                        :
                                                                        <>-{walletData[0].currency_symbol}{(((cartTotal + walletTransactionFee) - promoDiscountAmount) /100).toFixed(2)}</>
                                                                    }
                                                                    </>
                                                                    :
                                                                    <>
                                                                    {parseFloat(walletData[0].amount) <= (((cartTotal + transactionFee) - promoDiscountAmount) /100).toFixed(2) ?
                                                                        <>-{walletData[0].currency_symbol}{walletData[0].amount}</>
                                                                        :
                                                                        <>-{walletData[0].currency_symbol}{(((cartTotal + transactionFee) - promoDiscountAmount) /100).toFixed(2)}</>
                                                                    }
                                                                    </>
                                                                }
                                                                </>
                                                            }
                                                        </> 
                                                        }
                                                    </span>
                                                    {payTodayAmount > 0 ?
                                                    <>
                                                        {tip ? 
                                                            <>
                                                                {parseFloat(walletData[0].amount) >= (((payTodayAmount + walletTransactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2) ?
                                                                <>
                                                                    {parseFloat(walletData[0].amount) - (((payTodayAmount + walletTransactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2) >=0 &&
                                                                        <p className='text-sm font-light'>Remaining Wallet Balance: <strong className='font-bold'>{team.currency_symbol}{(parseFloat(walletData[0].amount) - (((payTodayAmount + walletTransactionFee + tipAmount) - promoDiscountAmount) /100)).toFixed(2)}</strong></p>
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    {parseFloat(walletData[0].amount) - (((payTodayAmount + transactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2) >=0 &&
                                                                    <p className='text-sm font-light'>Remaining Wallet Balance: <strong className='font-bold'>{team.currency_symbol}{(parseFloat(walletData[0].amount) - (((payTodayAmount + transactionFee + tipAmount) - promoDiscountAmount) /100)).toFixed(2)}</strong></p>
                                                                    }
                                                                </>
                                                                }                                                            
                                                            </>
                                                            :
                                                            <>
                                                                {parseFloat(walletData[0].amount) >= (((payTodayAmount + walletTransactionFee) - promoDiscountAmount) /100).toFixed(2) ?
                                                                <>
                                                                    {parseFloat(walletData[0].amount) - (((payTodayAmount + walletTransactionFee) - promoDiscountAmount) /100).toFixed(2) >=0 &&
                                                                        <p className='text-sm font-light'>Remaining Wallet Balance: <strong className='font-bold'>{team.currency_symbol}{(parseFloat(walletData[0].amount) - (((payTodayAmount + walletTransactionFee) - promoDiscountAmount) /100)).toFixed(2)}</strong></p>
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    {parseFloat(walletData[0].amount) - (((payTodayAmount + transactionFee) - promoDiscountAmount) /100).toFixed(2) >=0 &&
                                                                    <p className='text-sm font-light'>Remaining Wallet Balance: <strong className='font-bold'>{team.currency_symbol}{(parseFloat(walletData[0].amount) - (((payTodayAmount + transactionFee) - promoDiscountAmount) /100)).toFixed(2)}</strong></p>
                                                                    }
                                                                </>
                                                                }                                                             
                                                            </>                                                        
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {tip ? 
                                                            <>                                                            
                                                                {parseFloat(walletData[0].amount) >= (((cartTotal + walletTransactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2) ?
                                                                <>
                                                                    {parseFloat(walletData[0].amount) - (((cartTotal + walletTransactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2) >=0 &&
                                                                        <p className='text-sm font-light'>Remaining Wallet Balance: <strong className='font-bold'>{team.currency_symbol}{(parseFloat(walletData[0].amount) - (((cartTotal + walletTransactionFee + tipAmount) - promoDiscountAmount) /100)).toFixed(2)}</strong></p>
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    {parseFloat(walletData[0].amount) - (((cartTotal + transactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2) >=0 &&
                                                                    <p className='text-sm font-light'>Remaining Wallet Balance: <strong className='font-bold'>{team.currency_symbol}{(parseFloat(walletData[0].amount) - (((cartTotal + transactionFee + tipAmount) - promoDiscountAmount) /100)).toFixed(2)}</strong></p>
                                                                    }
                                                                </>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                {parseFloat(walletData[0].amount) >= (((cartTotal + walletTransactionFee) - promoDiscountAmount) /100).toFixed(2) ?
                                                                <>
                                                                    {parseFloat(walletData[0].amount) - (((cartTotal + walletTransactionFee) - promoDiscountAmount) /100).toFixed(2) >=0 &&
                                                                        <p className='text-sm font-light'>Remaining Wallet Balance: <strong className='font-bold'>{team.currency_symbol}{(parseFloat(walletData[0].amount) - (((cartTotal + walletTransactionFee) - promoDiscountAmount) /100)).toFixed(2)}</strong></p>
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    {parseFloat(walletData[0].amount) - (((cartTotal + transactionFee) - promoDiscountAmount) /100).toFixed(2) >=0 &&
                                                                    <p className='text-sm font-light'>Remaining Wallet Balance: <strong className='font-bold'>{team.currency_symbol}{(parseFloat(walletData[0].amount) - (((cartTotal + transactionFee) - promoDiscountAmount) /100)).toFixed(2)}</strong></p>
                                                                    }
                                                                </>
                                                                }
                                                            </>
                                                        }
                                                    </> 
                                                    }                                                 
                                                </div>
                                            </div>
                                            <div className='cart-detail-row cart-detail-total flex justify-between pb-5 mb-11'>
                                                <div className='cart-detail-total-label'>Remaining</div>
                                                <div className='cart-detail-total-value'>
                                                    {payTodayAmount > 0 ?
                                                        <>
                                                            {tip ? 
                                                                <>
                                                                {parseFloat(walletData[0].amount) <= (((payTodayAmount + transactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2) ?
                                                                    <>{walletData[0].currency_symbol}{((((payTodayAmount + transactionFee + tipAmount) - promoDiscountAmount) /100) - parseFloat(walletData[0].amount)).toFixed(2)}</>
                                                                    :
                                                                    <>{walletData[0].currency_symbol}0.00</>
                                                                }
                                                                </>
                                                                :
                                                                <>
                                                                {parseFloat(walletData[0].amount) <= (((payTodayAmount + transactionFee) - promoDiscountAmount) /100).toFixed(2) ?
                                                                    <>{walletData[0].currency_symbol}{((((payTodayAmount + transactionFee) - promoDiscountAmount) /100) - parseFloat(walletData[0].amount)).toFixed(2)}</>
                                                                    :
                                                                    <>{walletData[0].currency_symbol}0.00</>
                                                                }
                                                                </>                                                        
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {tip ? 
                                                                <>
                                                                {parseFloat(walletData[0].amount)<=(((cartTotal + transactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2) ?
                                                                    <>{walletData[0].currency_symbol}{((((cartTotal + transactionFee + tipAmount) - promoDiscountAmount) /100) - parseFloat(walletData[0].amount)).toFixed(2)}</>
                                                                    :
                                                                    <>{walletData[0].currency_symbol}0.00</>
                                                                }
                                                                </>
                                                                :
                                                                <>
                                                                {parseFloat(walletData[0].amount) <= (((cartTotal + transactionFee) - promoDiscountAmount) /100).toFixed(2) ?
                                                                    <>{walletData[0].currency_symbol}{((((cartTotal + transactionFee) - promoDiscountAmount) /100) - parseFloat(walletData[0].amount)).toFixed(2)}</>
                                                                    :
                                                                    <>{walletData[0].currency_symbol}0.00</>                                                                    
                                                                }
                                                                </>
                                                            }
                                                        </> 
                                                    }
                                                </div>
                                            </div>
                                            </>
                                            }
                                        </div>
                                        <div className='cart-form'>
                                            <div className='flex justify-between items-center gap-4 mb-5'>
                                                <input type='text' defaultValue={''} onChange={(e)=>{setPromocode(e.target.value)}} placeholder='Enter Discount Code' className='!pl-4' ref={promoRef} readOnly={promoCodeSuccess ? true : false} />
                                                {promoCodeSuccess ?
                                                <>                                                            
                                                    <button type='button' className='button button-blue' onClick={()=>{setPromoCodeSuccess(false); setPromoDiscountAmount(0); setPromocode(''); promoRef.current.value=''}}>Remove</button>
                                                </>                                                            
                                                :
                                                <>
                                                    {!promoloading ?
                                                        <button type='button' className='button button-blue' onClick={()=>{applyPromoCode()}}>Apply</button>
                                                        :
                                                        <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={12} height={12} alt="" />
                                                    }
                                                </>                                                            
                                                }
                                            </div>
                                            {promoCodeError!==null && <p className="error text-red-600 mb-5 -mt-3">{promoCodeError}</p>}
                                            <div className='form-field-wrap mb-5 email-field-wrap relative'>
                                                <input 
                                                    type="email" placeholder="Your Email Address" className='email-field'
                                                    value={currentUser?.email}
                                                    onChange={(e)=>{
                                                        setReceiptEmail(e.target.value)
                                                    }}
                                                />                                
                                            </div>
                                            <div className='form-field-wrap mb-5 location-field-wrap relative'>
                                                <input 
                                                    type="text" placeholder="Your Address" className='text-field'
                                                    value={currentUser?.address}
                                                    onChange={(e)=>{
                                                        setAddress(e.target.value)
                                                    }}
                                                />                                
                                            </div>
                                            <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                                <input
                                                    type="text" placeholder="Reference... e.g. Child's Name" className='text-field'
                                                    onChange={(e) => {
                                                        setReferenceChild(e.target.value)
                                                    }}
                                                />
                                            </div>
                                            {error!=='' &&
                                                <p className="error mb-2.5 text-red-600">{error}</p>
                                            }
                                            {!loading &&
                                                <div className='flex justify-end'>
                                                    <button type='button' className='button button--large' disabled={loadingSecrete} onClick={()=>getPaymentIntent()}>
                                                        {!loadingSecrete ?
                                                            'Proceed to payment'
                                                        :
                                                            'Wait'
                                                        }
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    </>
                                    }
                                    {clientSecret &&
                                    <>
                                        {payTodayAmount>0 &&
                                            <div className='cart-detail-row flex justify-between pb-2.5 mb-2.5'>
                                                <div className='cart-detail-label'>To Pay Today</div>
                                                <div className='cart-detail-value'>{team.currency_symbol}{(payTodayAmountDisplay / 100).toFixed(2)}</div>
                                            </div>
                                        }
                                        {(discountAmount>0 || promoDiscountAmount>0) &&
                                            <div className='cart-detail-row flex justify-between pb-2.5 mb-2.5'>
                                                <div className='cart-detail-label'>Discount</div>
                                                <div className='cart-detail-value'>{team.currency_symbol}{((discountAmount + promoDiscountAmount) / 100).toFixed(2)}</div>
                                            </div>
                                        }
                                        {isWallet ?
                                            <>
                                            <div className='cart-detail mb-5'>
                                                <div className='cart-detail-row cart-detail-total flex justify-between pb-5 mb-5'>
                                                    <div className='cart-detail-total-label'>Basket Total</div>
                                                    <div className='cart-detail-total-value'>
                                                        {payTodayAmount > 0 ?
                                                        <>
                                                            {tip ? 
                                                                <>
                                                                {team.currency_symbol}{(((payTodayAmount + transactionFee + tipAmount) -promoDiscountAmount ) /100).toFixed(2)}
                                                                </>
                                                                :
                                                                <>
                                                                {team.currency_symbol}{(((payTodayAmount + transactionFee) - promoDiscountAmount) /100).toFixed(2)}
                                                                </>                                                        
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                        {tip ? 
                                                            <>
                                                            {team.currency_symbol}{(((cartTotal + transactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2)}
                                                            </>
                                                            :
                                                            <>
                                                            {team.currency_symbol}{(((cartTotal + transactionFee) - promoDiscountAmount) /100).toFixed(2)}
                                                            </>
                                                        }
                                                        </>
                                                        }                                                    
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='cart-detail-row cart-detail-total flex justify-between pb-5 mb-5'>
                                                <div className='cart-detail-total-label'>PLAI Wallet</div>
                                                <div className='cart-detail-total-value'>
                                                    <span className='text-green-500'>
                                                        {payTodayAmount > 0 ?
                                                        <>
                                                            {tip ? 
                                                                <>
                                                                {parseFloat(walletData[0].amount) <= (((payTodayAmount + transactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2) ?
                                                                    <>-{walletData[0].currency_symbol}{walletData[0].amount}</>
                                                                    :
                                                                    <>-{walletData[0].currency_symbol}{(((payTodayAmount + transactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2)}</>
                                                                }
                                                                </>
                                                                :
                                                                <>
                                                                {parseFloat(walletData[0].amount) <= (((payTodayAmount + transactionFee) - promoDiscountAmount) /100).toFixed(2) ?
                                                                    <>-{walletData[0].currency_symbol}{walletData[0].amount}</>
                                                                    :
                                                                    <>-{walletData[0].currency_symbol}{(((payTodayAmount + transactionFee) - promoDiscountAmount) /100).toFixed(2)}</>
                                                                }
                                                                </>                                                        
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {tip ? 
                                                                <>
                                                                {parseFloat(walletData[0].amount)<=(((cartTotal + transactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2) ?
                                                                    <>-{walletData[0].currency_symbol}{walletData[0].amount}</>
                                                                    :
                                                                    <>-{walletData[0].currency_symbol}{(((cartTotal + transactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2)}</>
                                                                }
                                                                </>
                                                                :
                                                                <>
                                                                {parseFloat(walletData[0].amount) <= (((cartTotal + transactionFee) - promoDiscountAmount) /100).toFixed(2) ?
                                                                    <>-{walletData[0].currency_symbol}{walletData[0].amount}</>
                                                                    :
                                                                    <>-{walletData[0].currency_symbol}{(((cartTotal + transactionFee) - promoDiscountAmount) /100).toFixed(2)}</>                                                                    
                                                                }
                                                                </>
                                                            }
                                                        </> 
                                                        }
                                                    </span>
                                                    {payTodayAmount > 0 ?
                                                    <>
                                                        {tip ? 
                                                            <>
                                                            {parseFloat(walletData[0].amount) - (((payTodayAmount + transactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2) >=0 &&
                                                                <p className='text-sm font-light'>Remaining Wallet Balance: <strong className='font-bold'>{team.currency_symbol}{parseFloat(walletData[0].amount) - (((payTodayAmount + transactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2)}</strong></p>
                                                            }
                                                            </>
                                                            :
                                                            <>
                                                            {parseFloat(walletData[0].amount) - (((payTodayAmount + transactionFee) - promoDiscountAmount) /100).toFixed(2)>=0 &&
                                                                <p className='text-sm font-light'>Remaining Wallet Balance: <strong className='font-bold'>{team.currency_symbol}{parseFloat(walletData[0].amount) - (((payTodayAmount + transactionFee) - promoDiscountAmount) /100).toFixed(2)}</strong></p>
                                                            }
                                                            </>                                                        
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {tip ? 
                                                            <>
                                                            {parseFloat(walletData[0].amount) - (((cartTotal + transactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2) >=0 &&
                                                                <p className='text-sm font-light'>Remaining Wallet Balance: <strong className='font-bold'>{team.currency_symbol}{parseFloat(walletData[0].amount) - (((cartTotal + transactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2)}</strong></p>
                                                            }
                                                            </>
                                                            :
                                                            <>
                                                            {parseFloat(walletData[0].amount) - (((cartTotal + transactionFee) - promoDiscountAmount) /100).toFixed(2)>=0 &&
                                                                <p className='text-sm font-light'>Remaining Wallet Balance: <strong className='font-bold'>{team.currency_symbol}{parseFloat(walletData[0].amount) - (((cartTotal + transactionFee) - promoDiscountAmount) /100).toFixed(2)}</strong></p>
                                                            }
                                                            </>
                                                        }
                                                    </> 
                                                    }                                                 
                                                </div>
                                            </div>
                                            <div className='cart-detail-row cart-detail-total flex justify-between pb-5 mb-11'>
                                                <div className='cart-detail-total-label'>Total Amount</div>
                                                <div className='cart-detail-total-value'>
                                                    {payTodayAmount > 0 ?
                                                        <>
                                                            {tip ? 
                                                                <>
                                                                {parseFloat(walletData[0].amount) <= (((payTodayAmount + transactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2) ?
                                                                    <>{walletData[0].currency_symbol}{((((payTodayAmount + transactionFee + tipAmount) - promoDiscountAmount) /100) - parseFloat(walletData[0].amount)).toFixed(2)}</>
                                                                    :
                                                                    <>{walletData[0].currency_symbol}0.00</>
                                                                }
                                                                </>
                                                                :
                                                                <>
                                                                {parseFloat(walletData[0].amount) <= (((payTodayAmount + transactionFee) - promoDiscountAmount) /100).toFixed(2) ?
                                                                    <>{walletData[0].currency_symbol}{((((payTodayAmount + transactionFee) - promoDiscountAmount) /100) - parseFloat(walletData[0].amount)).toFixed(2)}</>
                                                                    :
                                                                    <>{walletData[0].currency_symbol}0.00</>
                                                                }
                                                                </>                                                        
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {tip ? 
                                                                <>
                                                                {parseFloat(walletData[0].amount)<=(((cartTotal + transactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2) ?
                                                                    <>{walletData[0].currency_symbol}{((((cartTotal + transactionFee + tipAmount) - promoDiscountAmount) /100) - parseFloat(walletData[0].amount)).toFixed(2)}</>
                                                                    :
                                                                    <>{walletData[0].currency_symbol}0.00</>
                                                                }
                                                                </>
                                                                :
                                                                <>
                                                                {parseFloat(walletData[0].amount) <= (((cartTotal + transactionFee) - promoDiscountAmount) /100).toFixed(2) ?
                                                                    <>{walletData[0].currency_symbol}{((((cartTotal + transactionFee) - promoDiscountAmount) /100)-parseFloat(walletData[0].amount)).toFixed(2)}</>
                                                                    :
                                                                    <>{walletData[0].currency_symbol}0.00</>                                                                    
                                                                }
                                                                </>
                                                            }
                                                        </> 
                                                    }
                                                    <p className='text-[10px] leading-[12px] font-light'>inclusive of all fees and taxes</p>
                                                </div>                                                
                                            </div>                                            
                                            </> 
                                            :
                                            <>
                                                <div className='cart-detail mb-5'>
                                                    <div className='cart-detail-row cart-detail-total flex justify-between pb-5 mb-11'>
                                                        <div className='cart-detail-total-label'>Total Amount</div>
                                                        <div className='cart-detail-total-value'>
                                                            {payTodayAmount > 0 ?
                                                            <>
                                                                {tip ? 
                                                                    <>
                                                                    {team.currency_symbol}{(((payTodayAmount + transactionFee + tipAmount) -promoDiscountAmount ) /100).toFixed(2)}
                                                                    </>
                                                                    :
                                                                    <>
                                                                    {team.currency_symbol}{(((payTodayAmount + transactionFee) - promoDiscountAmount) /100).toFixed(2)}
                                                                    </>                                                        
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                            {tip ? 
                                                                <>
                                                                {team.currency_symbol}{(((cartTotal + transactionFee + tipAmount) - promoDiscountAmount) /100).toFixed(2)}
                                                                </>
                                                                :
                                                                <>
                                                                {team.currency_symbol}{(((cartTotal + transactionFee) - promoDiscountAmount) /100).toFixed(2)}
                                                                </>
                                                            }
                                                            </>
                                                            } 
                                                            <p className='text-[10px] leading-[12px] font-light'>inclusive of all fees and taxes</p>                                                   
                                                        </div>
                                                    </div>
                                                </div>                                                
                                            </>                                           
                                        }
                                        <div className="cart-form payment-form">
                                            <CheckoutForm client_secret={clientSecret} intent_id={intentId} isSingle={false} />
                                        </div>
                                    </>
                                    }                                    
                                </div>
                            </div>
                        </>
                        :
                        <div className="empty text-center">
                            <div className="image"><img src={NoNews} alt="Plaisport" width={228} /></div>
                            <div className="title mb-5">Empty Cart?</div>
                            <div className="description mb-8">
                                <span>Your cart is empty, to buy our products click below.</span>                        
                            </div>
                            <button type="button" className='button button-yellow button-yellow-add' onClick={(e)=>{navigate('/team/'+team._id+'/shop')}}>Shop</button>
                        </div>
                    }
                </div>
            </div>           
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}
const ShopCheckout = ()=>{
    const { id } = useParams();
    return(        
        <>
            <Sidebar type="thin"/>
            <TeamContextProvider id={id}>
                <div id="page" className="page-checkout">
                    <AdminNavbar heading="Checkout"/>
                    <ShopcartView />
                </div>
            </TeamContextProvider>
        </>
    );
}

export default ShopCheckout;